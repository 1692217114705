import React from 'react';
import styles from './Layout.module.scss';

export interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div id="layout" className={styles.Layout}>
      {children}
    </div>
  );
};
