import config from '../config';
import axios from 'axios';
import { useMemo } from 'react';

enum RequestMethods {
  GET = 'get',
  POST = 'post',
}

export function useRequest() {
  const company = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);

    return queryParams.get('company');
  }, []);

  function makeUrl(path: string) {
    return `${config.BACKEND_URL}/api/${path}`;
  }

  async function makeRequest(method: RequestMethods, path: string, payload?: any) {
    const { data } = await axios({
      method: method,
      url: makeUrl(path),
      data: payload,
      responseType: 'json',
    });

    return data.result;
  }

  async function getStatus() {
    return makeRequest(RequestMethods.POST, `redeemer/status`, { company });
  }

  async function rollCode() {
    return makeRequest(RequestMethods.POST, `redeemer/roll`, { company });
  }

  async function claimCode(value: string) {
    return makeRequest(RequestMethods.POST, `redeemer/claim`, { company, value });
  }

  return {
    getStatus,
    rollCode,
    claimCode,
  };
}
