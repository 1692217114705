import React from 'react';
import cn from 'classnames';
import styles from './Counter.module.scss';

export interface CodePopupProps {
  className?: string;
  available: number;
}

export const Counter: React.FC<CodePopupProps> = ({ className, available }) => {
  return (
    <div className={cn(styles.Counter, className)}>
      <div className={styles.CounterTitle}>Осталось подарков</div>
      <div className={styles.CounterAvailable}>{available}</div>
    </div>
  );
};
