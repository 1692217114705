import React from 'react';
import styles from './Logo.module.scss';

export interface LogoProps {
  onClick: any;
}

export const Logo: React.FC<LogoProps> = ({ onClick }) => {
  return (
    <img className={styles.Logo} onClick={onClick} alt="Yandex Plus Logo" src={require('./images/logo.svg').default} />
  );
};
