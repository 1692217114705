import React, { useEffect, useState } from 'react';
import screenfull from 'screenfull';
import styles from './MainPage.module.scss';
import { Button } from '../../components/Button/Button';
import { Wheel } from '../../components/Wheel/Wheel';
import { CodePopup } from '../../components/CodePopup/CodePopup';
import { Counter } from '../../components/Counter/Counter';
import { Logo } from '../../components/Logo/Logo';
import { useRequest } from '../../hooks/useRequest';

export interface MainPageProps {}

export const MainPage: React.FC<MainPageProps> = () => {
  const [isRolling, setIsRolling] = useState<any>(false);
  const [isCodePopupVisible, setIsCodePopupVisible] = useState<any>(false);
  const [roll, setRoll] = useState<any>(null);
  const [available, setAvailable] = useState<any>(0);
  const { getStatus, rollCode } = useRequest();
  const actionButtonClassname = available ? '' : styles.MainPageActionsDisabled;

  function updateStatus() {
    getStatus().then(({ available }) => {
      setAvailable(available);
    });
  }

  function makeRoll() {
    setIsRolling(true);

    rollCode().then((roll) => {
      setRoll(roll);
    });
  }

  function showCodePopup() {
    setTimeout(() => {
      setIsCodePopupVisible(true);
      setIsRolling(false);
    }, 500);
  }

  function onClaimCode() {
    setIsCodePopupVisible(false);
    setRoll(null);
    updateStatus();
  }

  function handleFullscreen() {
    const element = document.getElementById('layout') as Element;

    if (screenfull.isEnabled) {
      screenfull.request(element, { navigationUI: 'hide' });
    }
  }

  useEffect(() => {
    updateStatus();
  }, [available]);

  return (
    <div className={styles.MainPage}>
      <Logo onClick={() => handleFullscreen()} />

      <div className={styles.MainPageTitle}>
        Крути барабан
        <br />и получай подарки
      </div>

      <div className={styles.MainPageActions}>
        <Button className={actionButtonClassname} disabled={isRolling} onClick={() => makeRoll()}>
          Крутить барабан
        </Button>
      </div>

      <div className={styles.MainPageRoll}>
        <Wheel
          className={styles.MainPageRollWheel}
          key={roll && roll.value}
          type={roll && roll.type}
          onAnimationEnd={showCodePopup}
        />
      </div>

      {Boolean(available) && <Counter className={styles.MainPageCounter} available={available} />}
      {isCodePopupVisible && Boolean(roll) && <CodePopup roll={roll} onClaimCode={onClaimCode} />}
    </div>
  );
};
