import React from 'react';
import cn from 'classnames';
import styles from './Wheel.module.scss';

export interface WheelProps {
  className?: string;
  type: string;
  onAnimationEnd: any;
}

export const Wheel: React.FC<WheelProps> = ({ className, type, onAnimationEnd }) => {
  const daysMap: any = {
    '360': 0,
    '180': 45,
    '90': 90,
    '30': 135,
  };
  const angle = type ? 360 * 6 + daysMap[type] : '360';

  return (
    <div className={cn(styles.Wheel, className)}>
      <div className={styles.slice}></div>

      <div
        className={cn(styles.WheelContainer, type ? styles.WheelAction : styles.WheelWait)}
        style={{ '--rotate-angle': `${angle}deg` } as React.CSSProperties}
        onAnimationEnd={onAnimationEnd}
      >
        <div className={cn(styles.line, styles.line1)} />
        <div className={cn(styles.line, styles.line2)} />
        <div className={cn(styles.line, styles.line3)} />
        <div className={cn(styles.line, styles.line4)} />
        <div className={styles.center} />

        <div className={cn(styles.label, styles.label1)}>360</div>
        <div className={cn(styles.label, styles.label2)}>30</div>
        <div className={cn(styles.label, styles.label3)}>90</div>
        <div className={cn(styles.label, styles.label4)}>180</div>
        <div className={cn(styles.label, styles.label5)}>360</div>
        <div className={cn(styles.label, styles.label6)}>30</div>
        <div className={cn(styles.label, styles.label7)}>90</div>
        <div className={cn(styles.label, styles.label8)}>180</div>
      </div>
    </div>
  );
};
