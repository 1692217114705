import React from 'react';
import cn from 'classnames';
import styles from './CodePopup.module.scss';
import QRCode from 'react-qr-code';
import { Button } from '../Button/Button';
import { useRequest } from '../../hooks/useRequest';

export interface CodePopupProps {
  className?: string;
  roll: any;
  onClaimCode: any;
}

export const CodePopup: React.FC<CodePopupProps> = ({ className, roll, onClaimCode }) => {
  const { claimCode } = useRequest();

  function claim() {
    claimCode(roll.value).then(() => {
      onClaimCode();
    });
  }

  return (
    <div className={cn(styles.CodePopup, className)}>
      <div className={styles.CodePopupContainer}>
        <div className={styles.CodePopupTitle}>А вот и ваш подарок!</div>
        <div className={styles.CodePopupCode}>
          <div className={styles.CodePopupCodeQR}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`https://plus.yandex.ru/gift?code=${roll.value}`}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div className={styles.CodePopupCodeTitle}>
            {roll.type} дней
            <br /> подписки
            <br /> Яндекс Плюс
          </div>
        </div>
        <Button className={styles.CodePopupCodeRedeem} onClick={() => claim()}>
          Спасибо
        </Button>
      </div>
    </div>
  );
};
